<template>
  <div id="app" :class="electronEnvironment">
    <top-bar v-if="electronEnvironment != 'environment-darwin'"/>
    <div class="darwinTopBar" v-if="electronEnvironment === 'environment-darwin'"/>
    <div class="outermost-wrap" v-show="showUser === 'route'">
      <master />
    </div>
    <div v-if="showUser === 'loading'" class="pre-auth">
      <div>
        <img src="~@/assets/new-chiclets/32x32@2x.png" alt="app-logo" />
        Securing your session...
      </div>
    </div>

    <p class="copyright">Alpha build {{ appVersion }}</p>
  </div>
</template>
<script>
import TopBar from "./components/TopBar.vue";
import Master from "@/views/v3/Master.vue";

export default {
  components: {
    TopBar,
    Master
  },
  computed: {
    showUser() {
      return this.$store.state.auth.showUser;
    },
    authenticationState() {
      return this.$store.state.auth.authenticationState;
    },
    appVersion() {
      console.log(process.env);
      console.log(process.env.VUE_APP_VERSION);
      return process.env.VUE_APP_VERSION;
    },
    electron() {
      return !!window.electron;
    },
    electronEnvironment() {
      if (!window.electron) {
        return "environment-browser"
      } else {
        return `environment-${window.electron.environment}`;
      }
    }
  }
};
</script>

<style lang="scss">
@import "normalize-scss";
$base-font-family: inherit;
@include normalize();

:root {
  @media (prefers-color-scheme: light) {
    --c-background-1: #fff;
    --c-background-2: #ccc;
    --c-text: #1c1e26;
  }
  @media (prefers-color-scheme: dark) {
    --c-background-1: #1c1e26;
    --c-background-2: #14161c;
    --c-text: #fff;
  }
  #app.environment-darwin {
    --c-background-2: transparent;
  }
}

// context specific variable values

body {
  margin: 0px;
}

#app {
  font-family: proxima-nova;
  letter-spacing: -0.008em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--c-text);
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

*:focus {
  outline: none;
}

#app {
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
  #checklist-wrap {
    flex-grow: 1;
  }

  p.copyright {
    position: fixed;
    bottom: 1em;
    right: 1em;
    margin: 0px;
    color: #fff;
    background-color: #5093bc;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 20px 64px rgba(0, 0, 0, 0.3);
    z-index: 99999;
  }
}
.outermost-wrap {
  display: flex;
  flex-direction: row;
  height: 100%;
  > div:not(.sb-wrap) {
    flex-grow: 1;
  }
}

.fa-hover-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
    svg {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      transition: 0.1s ease-in-out;
    }
  }
  svg {
    padding: 2px 0px;
    transition: 0.1s ease-in-out;
  }
}

.pre-auth {
  display: grid;
  place-items: center;
  text-align: center;
  height: 100%;
  font-size: 1.6em;
  font-weight: bolder;
  div {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}

:not(input):not(textarea),
:not(input):not(textarea)::after,
:not(input):not(textarea)::before {
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}
input,
button,
textarea,
:focus {
  outline: none; // You should add some other style for :focus to help UX/a11y
}

.darwinTopBar {
  position: fixed;
   -webkit-app-region: drag;
  -webkit-user-select: none;
  top: 0;
  height: 20px;
  width: 100vw;
}
</style>
