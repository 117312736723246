import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import electronAuthStore from "@/store/authElectron";
import classesStore from "@/store/classes";
import updatesStore from "@/store/updates";
import documentsStore from "@/store/documents";

export default new Vuex.Store({
  modules: {
    classes: classesStore,
    updates: updatesStore,
    auth: electronAuthStore,
    documents: documentsStore
  }
});
