<template>
  <div class="toolbar" :style="{ backgroundColor: `${context.color}25` }">
    <router-link id="back-button" to="/">⬅ Back</router-link>
    <p>
      <span class="emoji">{{ context.emoji }}</span>
      <span class="name">{{ context.name }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "Toolbar",
  props: {
    context: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.toolbar {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
  align-items: center;
  #back-button {
    color: grey;
    text-decoration: none;
    img {
      height: 100%;
    }
    &:hover {
      color: inherit;
    }
  }

  p {
    margin: 0px;
    margin-left: 16px;
    font-size: 26px;
    padding-left: 0px;
    .emoji {
      margin-right: 10px;
    }
    .name {
      font-weight: 600;
    }
  }
}
</style>
