<template>
  <div class="login">
    <div class="controls">
      <h1>Welcome back 👋</h1>
      <div class="line"></div>
      <a class="sign-in-with siw-m" :href="`${this.backendURL}/auth/azuread`">
        <login-image-microsoft />
        <p>Continue with Microsoft</p>
      </a>
      <a class="sign-in-with siw-g" :href="`${this.backendURL}/auth/github`">
        <login-image-github />
        <p>Continue with GitHub</p>
      </a>
      <a class="sign-in-with siw-m" @click="openBrowserAuthentication">
        <login-image-microsoft />
        <p>Continue in the browser</p>
      </a>
      <a class="sign-in-with siw-g" @click="openBrowserAuthentication">
        <p>Single auth</p>
      </a>
    </div>
  </div>
</template>

<script>
import LoginImageMicrosoft from "@/assets/auth-provider-logos/microsoft.svg";
import LoginImageGithub from "@/assets/auth-provider-logos/github.svg";
export default {
  name: "Login",
  components: {
    LoginImageMicrosoft,
    LoginImageGithub
  },
  computed: {
    backendURL() {
      return process.env.VUE_APP_BACKEND
    }
  },
  methods: {
    openBrowserAuthentication() {
      console.log(window.electron)
      window.electron.browserOpen("http://localhost:5000/auth/appAuthRequest")
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  flex-grow: 1;
  background-image: url(~@/assets/lowpoly.png);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  .controls {
    display: grid;
    padding: 32px;
    background-color: #ffffff10;
    backdrop-filter: blur(20px);
    color: #fff;
    min-width: 400px;
    grid-template-rows: 36px 8px repeat(2, 60px);
    gap: 10px;
    align-items: center;
    border-radius: 8px;
    h1 {
      margin: 0px;
      margin-bottom: 0.4em;
    }

    input,
    button,
    a {
      height: 100%;
      background-color: #ffffff30;
      border: none;
      border-radius: 4px;
      padding: 0px calc(60px / 3);
      color: #ffffff;
      outline: none;
      transition: 0.2s ease-in-out;

      &:hover,
      &:focus {
        transition: 0.2s ease-in-out;
        color: #2c3e50;
        background-color: #ffffff80;
      }
    }

    button,
    a {
      text-align: left;
      cursor: pointer;
      background-color: #ffffff70;
      color: #2c3e50;
      &:hover {
        background-color: #ffffff95;
      }
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .line {
      border-radius: 4px;
      width: 100%;
      background-color: #ffffff70;
      height: 2px;
    }
  }

  a.sign-in-with {
    padding: 0px;
    display: grid;
    grid-template-columns: 60px 1fr;
    svg {
      text-align: left;
      height: 100%;
      width: 100%;
      padding: 1em;
    }
    p {
      text-align: left;
      flex-grow: 1;
      margin: 0px;
    }
    &.siw-m {
      background-color: #ffffff;
    }
    &.siw-g {
      background-color: #212121;
      color: #fff;
      svg {
        filter: invert(1);
      }
    }
  }
}
</style>
