import axios from "axios";
import store from "@/store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND,
  withCredentials: true
})

instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${ store.state.auth.token }`;
    return config;
  }, 

  (error) => {
    return Promise.reject(error);
  }
);
export default instance;