const updatesStore = {
    state: {
        availableStatus: "pending",
        downloadingStatus: "pending"
    },

    mutations: {
        
    }
}

export default updatesStore;