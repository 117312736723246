<template>
  <div class="blocks-gallery">
    <div
      v-for="block in gallery"
      class="single-block-item"
      @click="addNew(block.default)"
      :key="block.id"
    >
      <p>{{ block.name }}</p>
      <img :src="block.image" />
    </div>
  </div>
</template>

<script>
import Gallery from "./Gallery";
export default {
  name: "BlockGallery",
  data: function() {
    return {
      gallery: Gallery
    };
  },
  props: {
    blocks: {
      type: Array,
      required: true
    }
  },
  methods: {
    addNew(block) {
      console.log(block);
      this.$props.blocks.push(block);
    }
  }
};
</script>

<style scoped lang="scss">
.blocks-gallery {
  background: #ddd;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .single-block-item {
    margin: 24px;
    display: flex;
    flex-direction: column;
    height: 100px;
    text-align: center;
    img {
      height: 60%;
    }
    p {
      height: 30%;
      margin: 0px;
      text-transform: uppercase;
      font-weight: 100;
    }
  }
}
</style>
