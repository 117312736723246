<template>
  <div class="class-view" v-if="formDataLoaded === 'success'">
    <toolbar :context="data" />
    <div class="inner-class">
      <sidebar :timetables="data.timetables" :id="data._id" />
      <router-view />
    </div>
  </div>
  <loading v-else />
</template>

<script>
import Toolbar from "@/views/class/Toolbar";
import Sidebar from "@/views/class/Sidebar";
import Loading from "@/components/Loading";
export default {
  name: "ClassView",
  components: {
    Toolbar,
    Sidebar,
    Loading
  },
  data: function() {
    return {
      data: {},
      formDataLoaded: false
    };
  },
  methods: {
    load: function() {
      return new Promise(() => {
        console.log(this.$route.params.id);
        this.$backendAPI({
          url:
            "/class/" + this.$route.params.id,
          method: "GET",
        })
          .then(resp => {
            //const user = {success: true}
            this.data = resp.data;
            this.formDataLoaded = "success";
          })
          .catch(err => {
            this.formDataLoaded = "fail";
            console.log(err);
          });
      });
    }
  },
  mounted: function mounted() {
    this.load();
  }
};
</script>

<style scoped lang="scss">
.class-view {
  flex-grow: 1;
  height: 100%;
  .inner-class {
    display: flex;
    height: 100%;
    ::v-deep #sidebar {
      min-width: 160px;
      border-right: 1px solid #dddddd;
    }
    ::v-deep .timetable {
      flex-grow: 1;
      overflow: auto;
    }
  }
}
</style>
