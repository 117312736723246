export default [
  {
    id: 0,
    name: "Paragraph",
    image: "https://www.flaticon.com/svg/static/icons/svg/893/893421.svg",
    default: { title: "", component: "paragraph", content: { value: "" } }
  },
  {
    id: 1,
    name: "Table",
    image: "https://www.flaticon.com/svg/static/icons/svg/930/930208.svg",
    default: { title: "", component: "paragraph", content: { value: "" } }
  }
];
