<template>
  <div id="sidebar">
    <router-link
      class="link"
      v-for="timetable of timetables"
      :to="'/class/' + id + '/timetable/' + timetable._id"
      :key="timetable._id"
      >{{ timetable.title }}</router-link
    >
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    timetables: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },

  mounted: function() {
    console.log(this.timetables);
  }
};
</script>

<style scoped lang="scss">
#sidebar {
  .link {
    display: block;
    padding: 10px;
    color: initial;
    text-decoration: none;
    width: 100%;
    height: auto;
    &:hover {
      background-color: #dddddd;
    }
  }
}
</style>
