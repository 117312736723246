<template>
  <editor
    :inline="true"
    v-model="contentModel"
    api-key="bk3wyt5fpe5ciyl89jk55pj6ozzwqzdqk37swz8sdhon65uo"
  />
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "Paragraph.vue",
  components: {
    editor: Editor
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      contentX: "<h1>testing</h1>"
    };
  },

  computed: {
    contentModel: {
      get: function() {
        return this.content.value;
      },
      set: function(newValue) {
        this.$props.content.value = newValue;
      }
    }
  },

  methods: {
    updateText() {
      this.$props.content.value = this.$refs.wysiwyg.innerHTML;
      console.log(this.$refs.wysiwyg.innerHTML);
    }
  }
};
</script>

<style scoped lang="scss">
div.wysiwyg {
  outline: none;
}

.mce-edit-focus {
  outline: none;
}
</style>
