import Vue from "vue";
import VueRouter from "vue-router";
import Checklist from "../views/checklist/Checklist.vue";
import Class from "../views/class/Class.vue";
import ClassView from "../views/class/ClassView.vue";
import Timetable from "@/views/class/timetable/Timetable";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "public" */ "../views/public/Login.vue")
  },
  {
    path: "/onboard/:user",
    name: "Welcome",
    component: () =>
      import(/* webpackChunkName: "public" */ "../views/public/Onboard.vue")
  },
  {
    path: "/class",
    name: "Home",
    component: Class
  },
  {
    path: "/checklist",
    name: "Checklist",
    component: Checklist
  },
  {
    path: "/class/:id",
    name: "ClassView",
    component: ClassView,
    children: [
      {
        path: "timetable/:class",
        name: "Timetable",
        component: Timetable
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  next();
  console.log(store);
});

export default router;
