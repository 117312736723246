import axios from "axios";

const classesStore = {
  state: {
    data: [],
    status: "pending"
  },
  mutations: {
    classes_request(state) {
      state.status = "pending";
    },
    classes_success(state, classes) {
      state.status = "authed";
      state.data = classes;
      console.log(classes);
    },
    classes_error(state) {
      state.status = "fail";
    }
  },
  actions: {
    get_classes({ commit }) {
      return new Promise(() => {
        commit("auth_request");

        axios({
          url: process.env.VUE_APP_BACKEND + "/class",
          method: "GET"
        })
          .then(resp => {
            //const user = {success: true}
            if (resp.data.authed === true) {
              commit("classes_success", resp.data);
            } else {
              commit("classes_error");
            }
          })
          .catch(err => {
            commit("auth_error");
            console.error(err);
            //reject(err)
          });
      });
    }
  }
};

export default classesStore;
