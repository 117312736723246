<template>
  <div id="checklist-wrap">
    <sidebar :lists="checklists" :active.sync="active" />
    <list v-if="active != null" v-model="checklists[active]" />
    <div v-else class="no-item-selected">
      <i>Please select a checklist from the sidebar.</i>
    </div>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import List from "./List";
import _ from "lodash";
export default {
  name: "Checklist.vue",
  components: {
    Sidebar,
    List
  },
  data: () => {
    return {
      checklists: [],
      active: null,
      formDataLoaded: "pending"
    };
  },

  methods: {
    save: _.debounce(function() {
      this.saveStatus = "Saving";
      return new Promise((resolve, reject) => {
        this.$backendAPI({
          url: process.env.VUE_APP_BACKEND + "/checklist",
          method: "POST",
          data: this.checklists,        })
          .then(() => {
            //const user = {success: true}
            this.saveStatus = "Saved";
          })
          .catch(err => {
            this.saveStatus = "Failed to save.";
            reject(err);
          });
      });
    }, 500),
    load: function() {
      return new Promise((resolve, reject) => {
        this.$backendAPI({
          url: "/checklist/",
          method: "GET",
        })
          .then(resp => {
            //const user = {success: true}
            this.checklists = resp.data;
            this.formDataLoaded = "success";
          })
          .catch(err => {
            this.formDataLoaded = "fail";
            reject(err);
          });
      });
    },
    newList: function() {
      return new Promise((resolve, reject) => {
        this.$backendAPI({
          url: "/checklist/new",
          method: "GET",
        })
          .then(resp => {
            //const user = {success: true}
            this.load();
            this.active = this.checklists.length;
            console.log(resp);
          })
          .catch(err => {
            this.formDataLoaded = "fail";
            reject(err);
          });
      });
    }
  },
  watch: {
    checklists: {
      handler: function() {
        this.saveStatus = "Waiting";
        this.save(); // call it in the context of your component object
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.load();
  }
};
</script>

<style scoped lang="scss">
#checklist-wrap {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

::v-deep input {
  outline: none;
  color: inherit;
}

.no-item-selected {
  flex-grow: 1;
  display: grid;
  place-items: center;
}
</style>
