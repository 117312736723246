<template>
  <div class="login">
    <div class="controls" v-if="formDataLoaded === 'success'">
      <h1>Welcome to HappyMe!</h1>
      <div class="line"></div>
      <input type="text" placeholder="first name" v-model="onboarding.first">
      <input type="text" placeholder="last name" v-model="onboarding.last">
      <div class="line"></div>
      <input type="email" placeholder="email address" v-model="onboarding.email">
      <button @click="register">Register</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: function () {
    return {
      onboarding: {},
      formDataLoaded: "pending"
    }
  },
  mounted() {
    return new Promise((resolve, reject) => {
      this.$backendAPI({
        url: `/auth/onboard/${this.$route.params.user}`,
        method: "GET",
      })
        .then(resp => {
          //const user = {success: true}
          this.onboarding = resp.data;
          this.formDataLoaded = "success";
        })
        .catch(err => {
          this.formDataLoaded = "fail";
          reject(err);
        });
    });
  },
  methods: {
    register() {
      return new Promise((resolve, reject) => {
        this.$backendAPI({
          url: `/auth/onboard/${this.$route.params.user}`,
          method: "POST",
          data: this.onboarding
        })
          .then(() => {
            //const user = {success: true}
            this.formDataLoaded = "authenticating";
            window.location.href = "/";
          })
          .catch(err => {
            this.formDataLoaded = "fail";
            reject(err);
          });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  flex-grow: 1;
  background-image: url(~@/assets/lowpoly.png);
  display: flex;
  align-items: center;
  justify-content: center;
  .controls {
    display: grid;
    padding: 32px;
    background-color: #ffffff10;
    backdrop-filter: blur(20px);
    color: #fff;
    min-width: 400px;
    grid-template-rows: 36px 8px repeat(2, 60px) 8px repeat(2, 60px);
    gap: 10px;
    align-items: center;
    border-radius: 8px;
    h1 {
      margin: 0px;
      margin-bottom: 0.4em;
    }

    input,
    button,
    a {
      height: 100%;
      background-color: #ffffff30;
      border: none;
      border-radius: 4px;
      padding: 0px calc(60px / 3);
      color: #ffffff;
      outline: none;
      transition: 0.2s ease-in-out;

      &:hover,
      &:focus {
        transition: 0.2s ease-in-out;
        color: #2c3e50;
        background-color: #ffffff80;
      }
    }

    button,
    a {
      text-align: left;
      cursor: pointer;
      background-color: #ffffff70;
      color: #2c3e50;
      &:hover {
        background-color: #ffffff95;
      }
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .line {
      border-radius: 4px;
      width: 100%;
      background-color: #ffffff70;
      height: 2px;
    }
  }

  a.sign-in-with {
    padding: 0px;
    display: grid;
    grid-template-columns: 60px 1fr;
    img {
      text-align: left;
      height: 100%;
      width: 100%;
      padding: 1em;
    }
    p {
      text-align: left;
      flex-grow: 1;
      margin: 0px;
    }
    &.siw-m {
      background-color: #ffffff;
    }
    &.siw-g {
      background-color: #212121;
      color: #fff;
      img {
        filter: brightness(10);
      }
    }
  }
}
</style>
