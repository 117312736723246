<template>
  <div class="workspace" :class="electronEnvironment" v-if="workspaceDocument">
    <div class="top-bar">
      <h1><input type="text" v-model="workspaceDocument.name" /></h1>
    </div>
  </div>
  <div class="workspace stateless"  :class="electronEnvironment" v-else>

  </div>
</template>

<script>
export default {
  name: "Workspace",
  computed: {
    workspaceDocument() {
      return this.$store.state.documents.documents[
        this.$store.state.documents.currentlySelected
      ];
    },
    electronEnvironment() {
      if (!window.electron) {
        return "environment-browser"
      } else {
        return `environment-${window.electron.environment}`;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.workspace {
  padding: 1.8em;
  background-color: var(--c-background-1);
  height: 100%;
  width: 100%;
  .top-bar {
    h1 {
      margin: 0;
      > input {
        font-weight: 500;
        border: none;
        background-color: transparent;
        color: var(--c-text);
        width: 100%;
        &::after {
          border-top: 1px solid darkgrey;
        }
      }
    }
  }
}
</style>
