<template>
  <div id="tb-wrap" :class="[windowType, windowState, electronEnvironment]">
    <span></span>
    <div class="left-align">
      <p @click="testLogout()" v-if="myName">Welcome, {{ myName }}!</p>
      <div
        class="platform-windows-controls"
        v-if="windowType === 'titleBarButtons'"
      >
        <button id="min" @click="minimize()">
          &#xE921;
        </button>
        <button
          id="restore"
          @click="restore()"
          v-if="windowState == 'maximize'"
        >
          &#xE923;
        </button>
        <button
          id="max"
          @click="maximize()"
          v-else-if="windowState == 'unmaximize'"
        >
          &#xE922;
        </button>
        <button id="close" @click="close()">
          &#xe711;
        </button>
      </div>
    </div>

    <loading v-if="showLoading" />
  </div>
</template>

<script>
import Loading from "./Loading";

export default {
  name: "TopBar",
  components: { Loading },
  data: function() {
    return {
      windowState: "unmaximize",
      showLoading: false
    };
  },
  computed: {
    windowType() {
      if (window.electron) {
        return window.electron.windowType;      
      } else {
        return "browser"
      }
    },
    myName() {
      return this.$store.state.auth.nameStringDefined;
    },
    electronEnvironment() {
      if (!window.electron) {
        return "environment-browser"
      } else {
        return `environment-${window.electron.environment}`;
      }
    }
  },
  methods: {
    close() {
      window.electron.windowControl.close()
      this.showLoading = true;
    },
    minimize() {
      window.electron.windowControl.minimise()
    },

    maximize() {
      window.electron.windowControl.maximise()
    },

    restore() {
      window.electron.windowControl.restore()
    },

    testLogout() {
      this.$store.dispatch("logout");
      console.log("attempting logout");
    }
  },

  mounted: function() {
    if (this.windowType != 'browser') {
      window.electron.windowState(data => {
        this.windowState = data;
      })
    }
  }
};
</script>

<style scoped lang="scss">
div#tb-wrap {
  width: 100vw;
  z-index: 500;
  display: flex;
  justify-content: space-between;
  background-color:  #002147;
  -webkit-app-region: drag;
  -webkit-user-select: none;
  height: 2em;
  //box-shadow: 0 20px 64px rgba(0, 0, 0, 0.3);
  svg {
    margin: 0px;
    color: #5093bd;
    height: 54px;
    object-fit: contain;
    object-position: left;
    padding: 12px 20px;
  }
  h1 {
    margin: 0px;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    padding: 10px;
  }
  .left-align {
    display: flex;
    align-items: center;
    -webkit-app-region: no-drag;
    p {
      color: #5093bd;
      font-size: 20px;
      margin: 0px;
      padding: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    .platform-windows-controls {
      height: 100%;
      button {
        font-family: "Segoe MDL2 Assets";
        height: 100%;
        width: 48px;
        background-color: transparent;
        border: none;
        outline: none;
        color: #5093bd;
        font-size: 14px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
          &#close {
            background-color: red;
            color: #fff;
          }
          background-color: #00000010;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }

  &.trafficLights.unmaximize {
    padding-left: 75px;
  }
}
</style>
