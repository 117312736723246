<template>
  <div class="timetable" v-if="data">
    <div class="this-blocks">
      <h1>{{ data.title }}</h1>
      <div class="block" v-for="block in data.blocks" :key="block.blockid">
        <h3>{{ block.title }}</h3>
        <component :is="block.component" :content="block.content" />
      </div>
    </div>
    <block-gallery :blocks="data.blocks" />
  </div>
</template>

<script>
import paragraph from "./blocks/Paragraph";
import BlockGallery from "./BlockGallery";

import socket from "@/socket";

export default {
  name: "Timetable",
  components: {
    BlockGallery,
    paragraph
  },
  data: function() {
    return {
      data: null
    };
  },
  methods: {
    load: function() {
      return new Promise((resolve, reject) => {
        console.log(this.$route.params.id);
        this.$backendAPI({
          url: `/class/${this.$route.params.id}/timetable/${this.$route.params.class}`,
          method: "GET",
        })
          .then(resp => {
            //const user = {success: true}
            this.data = resp.data;
            this.formDataLoaded = "success";
          })
          .catch(err => {
            this.formDataLoaded = "fail";
            reject(err);
          });
      });
    }
  },
  watch: {
    "$route.params": {
      handler() {
        this.load();
        socket.emit("currentPage", {
          class: this.$route.params.id,
          timetable: this.$route.params.class
        });
      },
      immediate: true
    },
    data: {
      handler: function(val) {
        this.$nextTick().then(() => {
          console.log("x data changed");
          console.log(this.$route.params.class);
          socket.send(val);
        });
      },
      deep: true
    }
  },
  mounted: function() {
    socket.on("message", data => {
      this.$withoutWatchers(() => {
        this.data = data;
      });
    });
  }
};
</script>

<style scoped lang="scss">
.this-blocks {
  padding: 24px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0px;
    margin-bottom: 0.6em;
  }
  margin: 0px;

  .block {
    border: 1px solid transparent;
    margin: 10px;
    margin-left: 0px;
    padding: 10px;
    transform: translateX(-10px);
    &:hover {
      border: 1px solid #dddddd;
    }
  }
}
</style>
