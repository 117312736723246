import backendAPI from "@/services/backendAPI";
import router from "../router";

const electronAuthStore = {
  state: {
    authenticationState: "success",
    showUser: "route",
    user: {},
    token: "",
    error: null
  },
  mutations: {
    auth_request(state) {
      console.log("T auth_request")
      state.authenticationStatus = "pending";
      console.log(state)
    },
    auth_success(state, user) {
      console.log("T auth_success")
      console.log(state)
      state.showUser = "loading";
      state.authenticationState = "success";
      state.user = user;
      router.push("/");
      state.showUser = "route";
      console.log(state)
    },
    auth_error(state, error) {
      console.log("T auth_error")
      console.log(state)
      state.authenticationState = "error";
      router.push("/login");
      state.showUser = "route";
      console.log(state)
      state.error = error;
    },
    set_api_token(state, token) {
      console.log("T set_api_token")
      console.log(token)
      state.token = token;
    }
  },
  actions: {
    login({ commit, state }) {
      return new Promise(done => {
        commit("auth_request");
        if (state.token === "") {
          commit("auth_error");
          return done(); // don't even try to authenticate the user if they don't have a token
        }

        backendAPI({
          url: "/auth/status",
          method: "GET"
        })
          .then(resp => {
            //const user = {success: true}
            if (resp.data.authed === true) {
              commit("auth_success", resp.data.user);
              return done();
            } else {
              commit("auth_error", "resp.data.authed was blank");
              return done();
            }
          })
          .catch(err => {
            commit("auth_error", err);
            console.error(err);
            done();
            //reject(err)
          });
      });
    },
    setUserToken({ commit, dispatch }, token) {
      commit("set_api_token", token.token);
      dispatch("login");
    }
  }
};

export default electronAuthStore;
