<template>
  <div class="list" :style="{ backgroundColor: listLocal.color }">
    <div class="titlebar">
      <input
        class="name"
        type="text"
        v-model="listLocal.name"
        placeholder="Double click to edit or add title."
      />
      <toolbar :color.sync="listLocal.color" />
    </div>
    <div class="items">
      <div v-for="(item, index) of listLocal.items" :key="index">
        <input type="checkbox" v-model="item.status" :id="index" />
        <input
          type="text"
          v-model="item.name"
          placeholder="Double click to edit or add title."
        />
      </div>
    </div>
    <img
      src="../../assets/flaticon/plus.svg"
      class="mutate"
      @click="addNewItem()"
    />
    <img
      src="../../assets/flaticon/minus.svg"
      class="mutate"
      @click="removeItem()"
    />
  </div>
</template>

<script>
import Toolbar from "./HeaderToolbar";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "List",
  components: {
    Toolbar
  },
  props: {
    list: Object
  },
  model: {
    prop: "list",
    event: "listchange"
  },
  methods: {
    addNewItem() {
      this.listLocal.items.push({
        uuid: uuidv4(),
        name: "",
        status: false,
        checklists_id: this.list.id
      });
    },
    removeItem() {
      this.listLocal.items.pop();
    }
  },
  computed: {
    listLocal: {
      get: function() {
        return this.list;
      },
      set: function(value) {
        this.$emit("listchange", value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.list {
  flex-grow: 1;
  .titlebar {
    display: flex;
    padding: 20px;
    backdrop-filter: contrast(1.37);
    .name {
      font-size: 32px;
      border: none;
      font-weight: 600;
      background-color: transparent;
      flex-grow: 1;
    }
  }

  .items {
    padding: 20px;
    div {
      display: flex;
      align-items: center;
      min-height: 35px;
      margin: 4px 0px;
      &:not(:first-child) {
        border-top: 2px solid #dddddd;
      }
      input[type="text"] {
        background-color: transparent;
        border: none;
        width: 100%;
        margin-left: 6px;
      }
    }
  }
  h1 {
    margin: 0px;
  }
  .mutate {
    height: 2.5em;
    border-radius: 50%;
    padding: 10px;
    margin-right: 10px;
    background-color: #ffffff95;
    transition: 0.2s ease-in-out;
    &:hover {
      transform: translateY(-2px);
      transition: 0.2s ease-in-out;
    }
    border: 1px solid #dddddd;
  }
}
</style>
