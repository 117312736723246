import Vue from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import backendAPI from "@/services/backendAPI";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.directive("click-outside", {
  bind() {
    this.event = event => this.vm.$emit(this.expression, event);
    this.el.addEventListener("click", this.stopProp);
    document.body.addEventListener("click", this.event);
  },
  unbind() {
    this.el.removeEventListener("click", this.stopProp);
    document.body.removeEventListener("click", this.event);
  },

  stopProp(event) {
    event.stopPropagation();
  }
});

Vue.mixin({
  methods: {
    $withoutWatchers(cb) {
      const watchers = this._watchers.map(watcher => ({
        cb: watcher.cb,
        sync: watcher.sync
      }));

      for (let index in this._watchers) {
        this._watchers[index] = Object.assign(this._watchers[index], {
          cb: () => null,
          sync: true
        });
      }

      cb();

      for (let index in this._watchers) {
        this._watchers[index] = Object.assign(
          this._watchers[index],
          watchers[index]
        );
      }
    }
  }
});

Vue.prototype.$backendAPI = backendAPI;
