const documentsStore = {
  state: {
    documents: [
      {
        name: "English, Day 1",
        contents: []
      },
      {
        name: "History, Day 1",
        contents: []
      },
      {
        name: "Mathematics, Day 6",
        contents: []
      }
    ],
    currentlySelected: false
  },
  mutations: {
    updateCurrentlySelected(state, documentIndex) {
      console.log(state, documentIndex)
      state.currentlySelected = documentIndex;
    },
    updateDocument(state, documentIndex, to) {
      console.log(to)
    }
  },
  actions: {}
};

export default documentsStore;
