<template>
    <div class="buttons">
      <div class="fa-hover-wrap">
        <icon-bin />
      </div>
      <div class="fa-hover-wrap">
        <icon-paint />
      </div>
    </div>
    
</template>

<script>
import IconBin from "@/assets/flaticon/bin.svg";
import IconPaint from "@/assets/flaticon/paint.svg";
export default {
  name: "HeaderToolbar",
  components: {
    IconBin,
    IconPaint
  },
  data: function() {
    return {
      show: {
        color: false
      },
      colors: ["#ff9aa2", "#ffb7b2", "#ffdac1", "#e2f0cb", "#b5ead7", "#c8ceaa"]
    };
  },
  methods: {
    toggleColorWheel: function() {
      this.show.color = !this.show.color;
    },
    updateColor(to) {
      this.$emit("update:color", to);
    }
  }
};
</script>

<style scoped lang="scss">
div.dialog {
  background-color: #ffffff95;
  border: 1px solid #dddddd;
  border-radius: 10px;
  position: absolute;
  right: 20px;
  padding: 20px;
  &#colorwheel {
    button {
      text-indent: -9999px;
      height: 20px;
      width: 20px;
      border: none;
      margin: 0px 2px;
    }
  }
}

.buttons {
  display: grid;
  grid-template-columns: repeat(2, 2.5em);
  gap: 4px;
  flex-shrink: 1;
}
.fa-hover-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
    svg {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      transition: 0.2s linear;
    }
  }
  svg {
    padding: 10px;
    height: 100%;
    transition: 0.2s linear;
  }
}
</style>
