<template>
  <div id="sidebar">
    <div
      v-for="(checklist, index) of lists"
      @click="updateActive(index)"
      :style="{ backgroundColor: checklist.color }"
      :id="index"
      :key="index"
    >
      {{ checklist.name }}
    </div>
    <button @click="newList">New List</button>
  </div>
</template>

<script>
export default {
  name: "Sidebar.vue",
  props: ["lists", "active"],
  methods: {
    updateActive(to) {
      console.log(to);
      this.$emit("update:active", to);
      console.log(this.active);
    },
    newList: function() {
      this.$parent.newList();
    }
  }
};
</script>

<style scoped lang="scss">
#sidebar {
  border-right: 1px solid #dddddd;
  min-width: 200px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  div {
    color: #2c3e50;
    text-decoration: none;
    border-bottom: 1px solid #dddddd;
    display: block;
    padding: 10px;
    cursor: pointer;
  }
  button {
    align-self: flex-end;
  }
}
</style>
