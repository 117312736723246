<template>
  <div class="champion">
    <documents-list />
    <workspace />
  </div>
</template>

<script>
import DocumentsList from "@/views/v3/DocumentsList";
import Workspace from "@/views/v3/Workspace";
export default {
  name: "Master",
  components: { DocumentsList, Workspace }
};
</script>

<style scoped lang="scss">
.champion {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100vw;
}
</style>
