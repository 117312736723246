<template>
  <div
    class="sidebar"
    :class="{ trafficLightSpace: electronEnvironment === 'environment-darwin' }"
    ref="sidebar"
  >
    <div class="documents">
      <button
        v-for="(document, index) in documents"
        :key="index"
        @click="setSelectedDocument(index)"
        :class="{ selected: index === currentlySelected }"
      >
        {{ document.name ? document.name : "Untitled" }}
      </button>
    </div>
    <div class="resizer" ref="resizer" />
  </div>
</template>

<script>
export default {
  name: "DocumentsList",
  computed: {
    documents() {
      return this.$store.state.documents.documents;
    },
    currentlySelected() {
      return this.$store.state.documents.currentlySelected;
    },
    electronEnvironment() {
      if (!window.electron) {
        return "environment-browser";
      } else {
        return `environment-${window.electron.environment}`;
      }
    }
  },
  methods: {
    setSelectedDocument(index) {
      console.log(index);
      this.$store.commit("updateCurrentlySelected", index);
    }
  }
};
</script>

<style scoped lang="scss">
.sidebar {
  .app {
    display: none;
  }
  &.trafficLightSpace {
    margin-top: 30px;
  }
  flex-basis: 250px;
  //background-color: #e3e3e3;
  overflow-y: auto;
  min-width: 150px;
  //border-right: 1px solid darkgrey;
  background: var(--c-background-2);
  .resizer {
    width: 10px;
    height: 10px;
    background-color: black;
    display: none;
  }
  .documents {
    display: flex;
    flex-direction: column;

    button {
      text-align: left;
      background: transparent;
      border: none;
      padding: 0.6em;
      color: var(--c-text);
      border-left: 3px solid transparent;
      transition: 0.05s ease-in-out;
      font-weight: 500;
      &:hover,
      &:focus-visible {
        transition: 0.05s ease-in-out;
        background-color: #ffffff40;
      }
      &.selected {
        border-left: 3px solid var(--c-text);
      }
    }
  }
}
</style>
