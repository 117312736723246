<template>
  <preload v-if="formDataLoaded === 'pending'" />
  <div v-else-if="formDataLoaded === 'success'" id="classes">
    <div class="grid">
      <router-link
        v-for="item of classes"
        :to="'/class/' + item._id"
        :key="item._id"
        :style="{ backgroundColor: item.color }"
      >
        <p class="emoji">{{ item.emoji }}</p>
        <p class="name">{{ item.name }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import Preload from "../../components/Preload";

export default {
  name: "Class.vue",
  components: {
    Preload
  },
  data: function() {
    return {
      formDataLoaded: "success",
      classes: []
    };
  },
  methods: {
    load: function() {
      return new Promise((resolve, reject) => {
        this.$backendAPI({
          url: "/class/",
          method: "GET",
        })
          .then(resp => {
            //const user = {success: true}
            this.classes = resp.data;
            this.formDataLoaded = "success";
          })
          .catch(err => {
            this.formDataLoaded = "fail";
            reject(err);
          });
      });
    }
  },
  mounted: function mounted() {
    this.load();
  }
  /*computed: {
    classes: function() {
      return this.$store.state.classes.data;
    }
  }*/
};
</script>

<style scoped lang="scss">
#classes {
  flex-grow: 1;
  .grid {
    display: grid;
    height: 100%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
    margin: 20px;
    a {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      height: 250px;
      text-decoration: none;
      p {
        margin: 0px;
        &.name {
          font-size: 1.5em;
        }
        &.emoji {
          font-size: 3.5em;
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
